@font-face {
  font-family: "Thin";
  src: url(../fonts/Inter-Thin.ttf);
}
@font-face {
  font-family: "Regular";
  src: url(../fonts/Inter-Regular.ttf);
}
@font-face {
  font-family: "Medium";
  src: url(../fonts/Inter-Medium.ttf);
}
@font-face {
  font-family: "SemiBold";
  src: url(../fonts/Inter-SemiBold.ttf);
}
@font-face {
  font-family: "Bold";
  src: url(../fonts/Inter-Bold.ttf);
}
span.blue {
  color: var(--primary-blue);
}
:root {
  --primary-blue: #2900ff;
  --transition: all 0.15s linear;
  --default_dark05: #00000080;
}
html {
  font-size: 10px;
  scroll-behavior: smooth;
}
body {
  position: relative;
  background-color: #fff /* rgb(10, 10, 5) */;
  font-family: Regular;
}
::-webkit-scrollbar {
  width: 5px;
  max-height: 50px;
  border-radius: 12px;
}
::-webkit-scrollbar-track {
  background: #0a0a05;
}
::-webkit-scrollbar-thumb {
  background-color: var(--primary-blue);
  border-radius: 200px;
  width: 10px;
}
canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
  z-index: 1;
}
p {
  line-height: 160%;
}
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  backdrop-filter: blur(5px);
  background-color: var(--default_dark05);
  align-items: center;
  padding: 2rem;
  box-sizing: border-box;
}
header * {
  color: #fff;
}
header nav {
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
header nav .languageBlock {
  display: flex;
  align-items: center;
}
header nav .languageBlock a.logo {
  font-size: 2.5rem;
  text-transform: uppercase;
  box-sizing: border-box;
}
header nav .languageBlock a.logo .blue {
  color: var(--primary-blue);
  font-family: "Bold";
}
header nav .languageBlock a.logo .white_underLine {
  position: relative;
  font-family: "Bold";
  padding-bottom: 0.1rem;
}
header nav .languageBlock a.logo .white_underLine::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 0.2rem;
  width: 98%;
  border-radius: 1rem;
  background-color: #fff;
}
header nav .languageBlock button {
  margin-left: 2rem;
  background-color: transparent;
  font-size: 1.4rem;
  font-family: "SemiBold";
  color: #bbb;
}
header nav .languageBlock button.ActiveLanguageButton {
  color: #fff;
}
header nav ul {
  display: flex;
  align-items: center;
}
header nav ul li {
  margin-right: 2rem;
}
header nav ul li button,
header nav ul li a {
  color: #fff;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.7rem;
  font-family: Medium;
  transition: var(--transition);
}
header nav ul li:hover button,
header nav ul li:hover a {
  color: var(--primary-blue);
}
header nav ul li .activeButton {
  color: var(--primary-blue);
  background-color: #191919;
}
header nav .routePCBlock {
  position: fixed;
  right: 0;
  border-radius: 0.5rem;
  transition: var(--transition);
  top: 6rem;
  box-sizing: border-box;
  max-height: 0;
  overflow: hidden;
  background-color: #191919;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  z-index: 1;
}
header nav .routePCBlock ul {
  display: flex;
  margin: 2rem;
  flex-direction: column;
  align-items: flex-start;
}
header nav .routePCBlock ul li {
  margin-bottom: 2rem;
}
header nav .routePCBlock ul li a {
  font: 1.6rem "SemiBold";
}
header nav .routePCBlock ul li:nth-last-child(1) {
  margin-bottom: 0;
}
header nav .routePCBlockDesign {
  transform: translateX(-210%);
}
header nav .routePCBlockDevelopment {
  right: 0;
  transform: translateX(-135%);
}
header nav .s {
  max-height: 30rem;
}
.MainBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  z-index: 2;
  min-height: 100vh;
  background-position: 10% 80%;
  background-size: cover;
}
.MainBlock .titleBlock {
  margin: 5rem auto;
}
.MainBlock .titleBlock h1 {
  color: #fff;
  font-family: Bold;
  text-transform: uppercase;
  font-size: 4.8rem;
  text-align: center;
  line-height: 140%;
}
.MainBlock .titleBlock h1 .underline {
  position: relative;
  padding-bottom: 0.4rem;
}
.MainBlock .titleBlock h1 .underline::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.3rem;
  background-color: var(--primary-blue);
  border-radius: 0.5rem;
}
.MainBlock .titleBlock h3 {
  color: #fff;
  font-size: 1.8rem;
  margin: 0 auto;
  padding: 2rem 0;
  line-height: 130%;
  max-width: 60%;
  border-radius: 10rem;
  box-sizing: border-box;
  background-color: var(--default_dark05);
  margin-top: 4rem;
  text-align: center;
}
.MainBlock .linksOnSite {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-top: 20rem;
}
.MainBlock .linksOnSite a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.6vh 0;
  width: 30%;
  box-sizing: border-box;
  border: 0.2rem solid #fff;
  border-radius: 0.5rem;
  color: #fff;
  font: 1.8rem "SemiBold";
  transition: var(--transition);
}
.MainBlock .linksOnSite a:hover {
  color: var(--primary-blue);
  border-color: var(--primary-blue);
}
.services {
  width: 100%;
  margin: 0 auto;
  padding: 4rem 0;
  box-sizing: border-box;
}
.services .container {
  width: 90%;
  margin: 0 auto;
}
.services .container h2 {
  color: #000;
  font: 3.2rem "SemiBold";
  animation: runFromLeft 0.3s ease-in-out forwards;
  animation-play-state: paused;
}
@keyframes runFromLeft {
  0% {
    transform: translateX(-10rem);
  }
  100% {
    transform: translateX(0rem);
  }
}
.services .container .tiles-container {
  display: flex;
  flex-wrap: wrap;
}
.services .container .tiles-container .tile {
  display: flex;
  margin-right: 3.33%;
  margin-top: 3.33%;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  box-sizing: border-box;
  width: 30%;
  min-height: 20rem;
  border-radius: 0.5rem;
  background-color: #f5f5f5;
  animation: runFormBottom 0.3s ease-in-out forwards;
  animation-play-state: paused;
  border: 0.2rem solid transparent;
  transition: all 0.3s ease-in-out;
}
@keyframes runFormBottom {
  0% {
    opacity: 0;
    transform: translateY(-10rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0rem);
  }
}
.services .container .tiles-container .tile ul {
  list-style: disc;
  margin-left: 2rem;
}
.services .container .tiles-container .tile ul li {
  font: 2.1rem "Medium";
}
.services .container .tiles-container .tile p {
  font-size: 1.6rem;
  font-family: "Regular";
  margin: 2rem 0;
}
.services .container .tiles-container .tile .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.services .container .tiles-container .tile .row a {
  display: flex;
  align-items: center;
  color: var(--primary-blue);
  font: 1.6rem "Medium";
  transition: var(--transition);
}
.services .container .tiles-container .tile .row a:hover img {
  transform: translateX(1rem);
}
.services .container .tiles-container .tile .row a img {
  width: 3.5rem;
  margin-left: 1rem;
  transition: all 0.3s ease-in-out;
  object-fit: contain;
}
.services .container .tiles-container .tile .row p {
  margin: 0;
  font-size: 1.6rem;
  font-family: SemiBold;
}
.services .container .tiles-container .tile:hover {
  border: 0.2rem solid #ddd;
}
.portfolio {
  width: 100%;
  padding: 4em 0;
  box-sizing: border-box;
  background-color: #0a0a05;
}
.portfolio .container {
  width: 90%;
  margin: 0 auto;
}
.portfolio .container h2 {
  color: #fff;
  font: 3.2rem "SemiBold";
}
.portfolio .container .tiles-container {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}
.portfolio .container .tiles-container .tile {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #1c1c1c;
  margin-top: 2.5%;
  width: 30%;
  margin-left: 2.5%;
  min-height: 30rem;
  overflow: hidden;
  background-size: cover;
  border-radius: 0.5rem;
  animation: runFromLeftPortfolio 0.3s ease-in-out forwards;
  animation-play-state: paused;
}
@keyframes runFromLeftPortfolio {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0rem);
  }
}
.portfolio .container .tiles-container .tile .bg {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 2rem;
  box-sizing: border-box;
  backdrop-filter: blur(5px);
  background-color: var(--default_dark05);
}
.portfolio .container .tiles-container .tile .bg .block p {
  color: #999;
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
}
.portfolio .container .tiles-container .tile .bg .block h3 {
  font-size: 2.1rem;
  color: #fff;
  text-transform: capitalize;
  font-family: "SemiBold";
}
.portfolio .container .tiles-container .tile .bg a {
  padding: 1rem 2rem;
  text-wrap: nowrap;
  box-sizing: border-box;
  background-color: #fff;
  font-size: 1.8rem;
  border-radius: 10rem;
}
.portfolio .container .tiles-container .tile-hidden {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}
.portfolio .container .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 3%;
}
.portfolio .container .pagination .linkk {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  font-size: 2rem;
  margin-right: 2rem;
  transition: var(--transition);
}
.portfolio .container .pagination .active {
  background-color: var(--primary-blue);
}
.formBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding: 4rem 0;
  box-sizing: border-box;
}
.formBlock h2 {
  font: 3.2rem "SemiBold";
}
.formBlock p {
  margin: 2rem 0;
  font: 1.5rem "SemiBold";
}
.formBlock form {
  display: flex;
  flex-direction: column;
  width: 48%;
}
.formBlock form input,
.formBlock form button,
.formBlock form select,
.formBlock form .css-b62m3t-container {
  width: 80%;
  border: 0;
  outline: 0;
  font-size: 1.6rem;
  margin-bottom: 2rem;
}
.formBlock form input {
  padding: 0.8rem;
  background-color: transparent;
  box-sizing: border-box;
  border: 0.1rem solid #ccc;
  transition: var(--transition);
  border-radius: 0.5rem;
}
.formBlock form input:focus {
  border-color: var(--primary-blue);
}
.formBlock form button {
  padding: 1.6rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background-color: var(--primary-blue);
  color: #fff;
  cursor: pointer;
  font-family: "SemiBold";
}
.formBlock form input,
.formBlock form button,
.formBlock form select {
  position: relative;
  z-index: 4;
}
.formBlock form .css-1nmdiq5-menu {
  z-index: 5;
}
@keyframes scrollFromLeft {
  0% {
    transform: translateX(-10rem);
  }
  100% {
    transform: translateX(0rem);
  }
}
.formBlock .whiteBlock {
  width: 48%;
  object-fit: contain;
}
.whyWe {
  width: 100%;
  padding: 4rem 0;
  background-color: #0a0a05;
  position: relative;
}
.whyWe .float {
  position: absolute;
  background-color: #1e2021;
  height: 20rem;
  width: 20rem;
}
.whyWe .floatBottomTrinagle {
  clip-path: polygon(100% 100%, 100% 20%, 20% 100%);
  z-index: 1;
  bottom: 0;
  right: 0;
}
.whyWe .floatTopTrinagle {
  clip-path: polygon(0 0, 80% 0, 0 80%);
  top: 0;
  left: 0;
}
.whyWe .container {
  width: 90%;
  margin: 7rem auto;
}
.whyWe .container h2 {
  font: 3.2rem "SemiBold";
  color: #fff;
}
.whyWe .container .tiles-container {
  display: flex;
  flex-wrap: wrap;
}
.whyWe .container .tiles-container .tile {
  width: 30%;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  margin-right: 3.33%;
  margin-top: 3.33%;
  animation: runFromTopWe 0.3s ease-in-out forwards;
  animation-play-state: paused;
}
@keyframes runFromTopWe {
  0% {
    transform: translateY(10rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
}
.whyWe .container .tiles-container .tile .row {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.whyWe .container .tiles-container .tile .row h4 {
  margin-right: 1rem;
  color: #656565;
  font: 1.4rem "Bold";
}
.whyWe .container .tiles-container .tile .row h3 {
  color: #fff;
  font: 2rem "Medium";
}
.whyWe .container .tiles-container .tile p {
  font: 1.6rem "Regular";
  color: #919191;
}
.aboutUs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  min-height: 70rem;
  padding: 4rem 0;
  box-sizing: border-box;
}
.aboutUs .left {
  width: 48%;
}
.aboutUs .left h2 {
  font: 3.2rem "SemiBold";
}
.aboutUs .left h3 {
  font: 2.2rem "Medium";
  margin-top: 2rem;
}
.aboutUs .left p {
  margin-top: 2rem;
  font: 1.8rem "Regular";
}
.aboutUs .left p a {
  font-size: 1.6rem;
  color: var(--primary-blue);
  text-decoration: underline;
}
.aboutUs .right {
  display: flex;
  justify-content: center;
  width: 48%;
}
.aboutUs .right .letters {
  font: 25rem "Bold";
  color: #e8e8e8;
}
.ContactsSection {
  width: 100%;
  padding: 4rem 0;
  box-sizing: border-box;
  background-color: #0a0a05;
}
.ContactsSection .container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
}
.ContactsSection .container .left {
  width: 65%;
}
.ContactsSection .container .left h2 {
  font: 5rem "SemiBold";
  color: #fff;
  margin-bottom: 2rem;
}
.ContactsSection .container .left h3 {
  color: #fff;
  font: 1.4rem "Medium";
}
.ContactsSection .container .left .block {
  margin-top: 4rem;
}
.ContactsSection .container .left .block p {
  font: 1.6rem "Regular";
  color: #828282;
}
.ContactsSection .container .left .block a {
  color: #fff;
  margin-top: 1rem;
  text-decoration: underline;
  font: 1.8rem "Medium";
}
.ContactsSection .container .right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 30%;
}
.ContactsSection .container .right .linkToForm {
  display: flex;
  justify-content: center;
  padding: 2rem;
  box-sizing: border-box;
  font: 1.8rem "Medium";
  width: 100%;
  border-radius: 0.5rem;
  color: #fff;
  background-color: var(--primary-blue);
}
.ContactsSection .container .right .row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
}
.ContactsSection .container .right .row a {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}
.ContactsSection .container .right .row a img {
  width: 2rem;
  object-fit: contain;
  margin-right: 1rem;
}
.ContactsSection .container .right .row .tg {
  color: #23a0dd;
}
.ContactsSection .container .right .row .wa {
  color: #7ad06d;
}
footer {
  width: 100%;
  padding: 4rem 0;
  background-color: #f3f3f3;
  box-sizing: border-box;
}
footer nav {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
}
footer nav .block.col-j-e {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
footer nav .block.col-j-e p {
  position: relative;
  padding-bottom: 0.6rem;
  box-sizing: border-box;
}
footer nav .block.col-j-e p::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60%;
  height: 0.2rem;
  background-color: var(--primary-blue);
}
footer nav .block a.logo {
  text-transform: uppercase;
  font-size: 2.5rem;
}
footer nav .block a.logo .blue {
  color: var(--primary-blue);
  font-family: "Bold";
}
footer nav .block a.logo .black_underLine {
  color: #000;
  position: relative;
  font-family: "Bold";
  padding-bottom: 0.1rem;
}
footer nav .block a.logo .black_underLine::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 0.2rem;
  width: 98%;
  background-color: #000;
}
footer nav .block .inblock {
  margin-top: 4rem;
}
footer nav .block .inblock p {
  font: 1.8rem "Medium";
  margin-bottom: 0.5rem;
}
footer nav .block .inblock a {
  font: 1.6rem "Regular";
  text-decoration: underline;
}
footer nav .block .inblockRow {
  display: flex;
  margin-top: 4rem;
}
footer nav .block .inblockRow a {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-family: "SemiBold";
  margin-right: 2rem;
}
footer nav .block .inblockRow a img {
  width: 2rem;
  object-fit: contain;
  margin-right: 1rem;
}
footer nav .block .inblockRow .tg {
  color: #23a0dd;
}
footer nav .block .inblockRow .wa {
  color: #7ad06d;
}
footer nav .block h3 {
  font: 1.8rem "SemiBold";
}
footer nav .block ul {
  margin-top: 4rem;
}
footer nav .block ul li {
  margin-top: 1rem;
}
footer nav .block ul li a {
  font-size: 1.6rem;
  transition: var(--transition);
  color: #555;
}
footer nav .block ul li:hover a {
  color: var(--primary-blue);
}
footer .bottom-block {
  width: 90%;
  margin: 0 auto;
  margin-top: 2rem;
  padding-top: 2rem;
  box-sizing: border-box;
  border-top: 0.2rem solid #0000004d;
}
footer .bottom-block p {
  color: #000000b3;
  font-size: 1.3rem;
}
.mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .mobile {
    display: unset;
  }
  .pc {
    display: none;
  }
  body,
  html {
    overflow-x: hidden;
    max-width: 100vw;
  }
  header {
    position: static;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    backdrop-filter: blur(5px);
    background-color: var(--default_dark05);
    align-items: center;
    padding: 2rem;
    box-sizing: border-box;
  }
  header * {
    color: #fff;
  }
  header nav {
    display: flex;
    width: 95%;
    justify-content: space-between;
    align-items: center;
  }
  header nav .languageBlock {
    display: flex;
    align-items: center;
  }
  header nav .languageBlock a.logo {
    font-size: 2.5rem;
    text-transform: uppercase;
    box-sizing: border-box;
  }
  header nav .languageBlock a.logo .blue {
    color: var(--primary-blue);
    font-family: "Bold";
  }
  header nav .languageBlock a.logo .white_underLine {
    position: relative;
    font-family: "Bold";
    padding-bottom: 0.1rem;
  }
  header nav .languageBlock a.logo .white_underLine::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    height: 0.2rem;
    width: 98%;
    border-radius: 1rem;
    background-color: #fff;
  }
  header nav .languageBlock button {
    display: none;
    margin-left: 2rem;
    background-color: transparent;
    font-size: 1.4rem;
    font-family: "SemiBold";
    color: #bbb;
  }
  header nav .languageBlock button.ActiveLanguageButton {
    color: #fff;
  }
  header nav ul {
    display: flex;
    align-items: center;
  }
  header nav ul li {
    display: none;
    margin-right: 2rem;
  }
  header nav ul li a {
    color: #fff;
    font-size: 1.7rem;
    font-family: Medium;
    transition: var(--transition);
  }
  header nav ul li:hover a {
    color: var(--primary-blue);
  }
  header nav ul .mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 4rem;
    margin: 0;
  }
  header nav ul .mobile button {
    width: 3rem;
    height: 0.3rem;
    border-radius: 0.5rem;
    background-color: #fff;
    transition: var(--transition);
  }
  header nav ul .mobile .activeBurgerButton:nth-child(1) {
    opacity: 0;
  }
  header nav ul .mobile .activeBurgerButton:nth-child(2) {
    position: relative;
    transform: rotateZ(48deg);
  }
  header nav ul .mobile .activeBurgerButton:nth-child(3) {
    position: relative;
    transform: rotateZ(-48deg);
    top: -1rem;
  }
  .burger.mainBlack {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 8.29vh;
    left: 0;
    width: 0;
    overflow: hidden;
    transition: all 0.15s ease-in-out;
    height: 91vh;
    backdrop-filter: blur(5px);
    background-color: var(--default_dark05);
    z-index: 3;
  }
  .burger.mainBlack button,
  .burger.mainBlack .defaultLink {
    width: 80%;
    text-align: left;
    background-color: transparent;
    box-sizing: border-box;
    color: #fff;
    margin-top: 2rem;
    font: 2rem "SemiBold";
  }
  .burger.mainBlack .block {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    width: 80%;
    max-height: 0;
    overflow: hidden;
    transition: all 0.15s ease-in-out;
  }
  .burger.mainBlack .block a {
    font: 1.8rem "Medium";
    color: #ccc;
    margin: 0.5rem 0;
  }
  .burger.mainBlack .contacts a {
    text-decoration: underline;
  }
  .burger.mainBlack .activeBurgerBlock {
    max-height: 30rem;
  }
  .activeBurger.mainBlack {
    width: 60%;
  }
  .MainBlock {
    display: flex;
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    position: relative;
    top: -8rem;
    width: 100%;
    z-index: 2;
    min-height: 100vh;
  }
  .MainBlock .titleBlock {
    margin: 5rem auto;
    width: 90%;
  }
  .MainBlock .titleBlock h1 {
    color: #fff;
    font-family: Bold;
    text-transform: unset;
    font-size: 3.2rem;
    text-align: unset;
    line-height: 140%;
  }
  .MainBlock .titleBlock h3 {
    color: #fff;
    font-size: 1.8rem;
    margin: 0 auto;
    padding: 2rem 0;
    line-height: 130%;
    max-width: 100%;
    border-radius: 10rem;
    box-sizing: border-box;
    background-color: var(--default_dark05);
    margin-top: 4rem;
    text-align: center;
  }
  .MainBlock .linksOnSite {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    margin: 10rem auto;
  }
  .MainBlock .linksOnSite a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    padding: 1.6vh 0;
    width: 100%;
    box-sizing: border-box;
    border: 0.2rem solid #fff;
    border-radius: 0.5rem;
    color: #fff;
    font: 1.8rem "SemiBold";
    transition: var(--transition);
  }
  .MainBlock .linksOnSite a:hover {
    color: var(--primary-blue);
    border-color: var(--primary-blue);
  }
  .services {
    width: 100%;
    margin: 0 auto;
    padding: 4rem 0;
    box-sizing: border-box;
  }
  .services .container {
    width: 90%;
    margin: 0 auto;
  }
  .services .container h2 {
    color: #000;
    font: 3.2rem "SemiBold";
    animation: runFromLeft 0.3s ease-in-out forwards;
    animation-play-state: running;
  }
  @keyframes runFromLeft {
    0% {
      transform: translateX(-10rem);
    }
    100% {
      transform: translateX(0rem);
    }
  }
  .services .container .tiles-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .services .container .tiles-container .tile {
    display: flex;
    margin-right: 0;
    margin-top: 2rem;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
    box-sizing: border-box;
    width: 100%;
    min-height: 20rem;
    border-radius: 0.5rem;
    background-color: #f5f5f5;
  }
  .services .container .tiles-container .tile ul {
    list-style: disc;
    margin-left: 2rem;
  }
  .services .container .tiles-container .tile ul li {
    font: 2.1rem "Medium";
  }
  .services .container .tiles-container .tile p {
    font-size: 1.6rem;
    font-family: "Regular";
    margin: 2rem 0;
  }
  .services .container .tiles-container .tile .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .services .container .tiles-container .tile .row a {
    display: flex;
    align-items: center;
    color: var(--primary-blue);
    font: 1.6rem "Medium";
    transition: var(--transition);
  }
  .services .container .tiles-container .tile .row a:hover {
    text-decoration: underline;
  }
  .services .container .tiles-container .tile .row a img {
    width: 3.5rem;
    margin-left: 1rem;
    object-fit: contain;
  }
  .services .container .tiles-container .tile .row p {
    margin: 0;
    font-size: 1.6rem;
    font-family: SemiBold;
  }
  .portfolio {
    width: 100%;
    padding: 4em 0;
    box-sizing: border-box;
    background-color: #0a0a05;
  }
  .portfolio .container {
    width: 90%;
    margin: 0 auto;
  }
  .portfolio .container h2 {
    color: #fff;
    font: 2.6rem "SemiBold";
  }
  .portfolio .container .tiles-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
  }
  .portfolio .container .tiles-container .tile {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #1c1c1c;
    margin-right: 0;
    margin-top: 2rem;
    width: 100%;
    margin-left: 0;
    min-height: 30rem;
    background-size: cover;
    border-radius: 0.5rem;
  }
  .portfolio .container .tiles-container .tile .bg {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 2rem;
    box-sizing: border-box;
    background-color: var(--default_dark05);
  }
  .portfolio .container .tiles-container .tile .bg .block p {
    color: #bbb;
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
  }
  .portfolio .container .tiles-container .tile .bg .block h3 {
    font-size: 2.1rem;
    color: #fff;
    text-transform: capitalize;
    font-family: "SemiBold";
  }
  .portfolio .container .tiles-container .tile .bg a {
    padding: 1rem 2rem;
    text-wrap: nowrap;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 1.8rem;
    border-radius: 10rem;
  }
  .portfolio .container .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 3%;
  }
  .portfolio .container .pagination .linkk {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    font-size: 2rem;
    margin-right: 2rem;
    transition: var(--transition);
  }
  .portfolio .container .pagination .active {
    background-color: var(--primary-blue);
  }
  .formBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    padding: 4rem 0;
    box-sizing: border-box;
  }
  .formBlock h2 {
    font: 2.6rem "SemiBold";
  }
  .formBlock p {
    margin: 2rem 0;
    font: 1.5rem "SemiBold";
  }
  .formBlock form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .formBlock form input,
  .formBlock form button,
  .formBlock form select,
  .formBlock form .css-b62m3t-container {
    width: 100%;
    border: 0;
    outline: 0;
    font-size: 1.6rem;
    margin-bottom: 2rem;
    animation: unset;
  }
  .formBlock form input {
    padding: 0.8rem;
    background-color: transparent;
    box-sizing: border-box;
    border: 0.1rem solid #cccccc;
    transition: var(--transition);
    border-radius: 0.5rem;
  }
  .formBlock form input:focus {
    border-color: var(--primary-blue);
  }
  .formBlock form button {
    padding: 1.6rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background-color: var(--primary-blue);
    color: #fff;
    cursor: pointer;
    font-family: "SemiBold";
  }
  .formBlock .whiteBlock {
    display: none;
    width: 100%;
    object-fit: contain;
  }
  .whyWe {
    width: 100%;
    padding: 4rem 0;
    background-color: #0a0a05;
    position: relative;
  }
  .whyWe .float {
    position: absolute;
    background-color: #1e2021;
    height: 15rem;
    width: 15rem;
  }
  .whyWe .floatBottomTrinagle {
    clip-path: polygon(100% 100%, 100% 20%, 20% 100%);
    z-index: 1;
    bottom: 0;
    right: 0;
  }
  .whyWe .floatTopTrinagle {
    clip-path: polygon(0 0, 80% 0, 0 80%);
    top: 0;
    left: 0;
  }
  .whyWe .container {
    width: 90%;
    margin: 7rem auto;
  }
  .whyWe .container h2 {
    font: 2.6rem "SemiBold";
    color: #fff;
  }
  .whyWe .container .tiles-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .whyWe .container .tiles-container .tile {
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    margin-right: 3.33%;
    margin-top: 3.33%;
  }
  .whyWe .container .tiles-container .tile .row {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  .whyWe .container .tiles-container .tile .row h4 {
    margin-right: 1rem;
    color: #656565;
    font: 1.4rem "Bold";
  }
  .whyWe .container .tiles-container .tile .row h3 {
    color: #fff;
    font: 2rem "Medium";
  }
  .whyWe .container .tiles-container .tile p {
    font: 1.6rem "Regular";
    color: #919191;
  }
  .aboutUs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    padding: 4rem 0;
    box-sizing: border-box;
  }
  .aboutUs .left {
    width: 100%;
  }
  .aboutUs .left h2 {
    font: 2.6rem "SemiBold";
  }
  .aboutUs .left h3 {
    font: 2.2rem "Medium";
    margin-top: 2rem;
  }
  .aboutUs .left p {
    margin-top: 2rem;
    font: 1.8rem "Regular";
  }
  .aboutUs .left p a {
    font-size: 1.6rem;
    color: var(--primary-blue);
    text-decoration: underline;
  }
  .aboutUs .right {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .aboutUs .right .letters {
    font: 25rem "Bold";
    color: #e8e8e8;
  }
  .ContactsSection {
    width: 100%;
    padding: 4rem 0;
    box-sizing: border-box;
    background-color: #0a0a05;
  }
  .ContactsSection .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
  }
  .ContactsSection .container .left {
    width: 100%;
  }
  .ContactsSection .container .left h2 {
    font: 2.8rem "SemiBold";
    color: #fff;
    margin-bottom: 2rem;
  }
  .ContactsSection .container .left h3 {
    color: #fff;
    font: 1.4rem "Medium";
  }
  .ContactsSection .container .left .block {
    margin-top: 4rem;
  }
  .ContactsSection .container .left .block p {
    font: 1.6rem "Regular";
    color: #828282;
  }
  .ContactsSection .container .left .block a {
    color: #fff;
    text-decoration: underline;
    font: 1.8rem "Medium";
  }
  .ContactsSection .container .right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 3rem;
    width: 100%;
  }
  .ContactsSection .container .right .linkToForm {
    display: flex;
    justify-content: center;
    padding: 2rem;
    box-sizing: border-box;
    font: 1.8rem "Medium";
    width: 100%;
    border-radius: 0.5rem;
    color: #fff;
    background-color: var(--primary-blue);
  }
  .ContactsSection .container .right .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 2rem;
  }
  .ContactsSection .container .right .row a {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
  }
  .ContactsSection .container .right .row a img {
    width: 2rem;
    object-fit: contain;
    margin-right: 1rem;
  }
  .ContactsSection .container .right .row .tg {
    color: #23a0dd;
  }
  .ContactsSection .container .right .row .wa {
    color: #7ad06d;
  }
  footer {
    width: 100%;
    padding: 4rem 0;
    box-sizing: border-box;
  }
  footer nav {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    justify-content: space-between;
  }
  footer nav .block.col-j-e {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  footer nav .block.col-j-e .inblock p {
    position: relative;
    font-size: 1.8rem;
    padding-bottom: 0.6rem;
    box-sizing: border-box;
  }
  footer nav .block.col-j-e .inblock p::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40%;
    height: 0.2rem;
    background-color: var(--primary-blue);
  }
  footer nav .block {
    margin-bottom: 3rem;
  }
  footer nav .block a.logo {
    text-transform: uppercase;
    font-size: 2.5rem;
  }
  footer nav .block a.logo .blue {
    color: var(--primary-blue);
    font-family: "Bold";
  }
  footer nav .block a.logo .black_underLine {
    color: #000;
    position: relative;
    font-family: "Bold";
    padding-bottom: 0.1rem;
  }
  footer nav .block a.logo .black_underLine::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    height: 0.2rem;
    width: 98%;
    background-color: #000;
  }
  footer nav .block .inblock {
    margin-top: 4rem;
  }
  footer nav .block .inblock p {
    font: 1.4rem "bold";
    margin-bottom: 0.5rem;
  }
  footer nav .block .inblock a {
    font: 1.6rem "Regular";
    text-decoration: underline;
  }
  footer nav .block .inblockRow {
    display: flex;
    margin-top: 4rem;
  }
  footer nav .block .inblockRow a {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-family: "SemiBold";
    margin-right: 2rem;
  }
  footer nav .block .inblockRow a img {
    width: 2rem;
    object-fit: contain;
    margin-right: 1rem;
  }
  footer nav .block .inblockRow .tg {
    color: #23a0dd;
  }
  footer nav .block .inblockRow .wa {
    color: #7ad06d;
  }
  footer nav .block h3 {
    font: 1.8rem "SemiBold";
  }
  footer nav .block ul {
    margin-top: 2rem;
  }
  footer nav .block ul li {
    margin-top: 1rem;
  }
  footer nav .block ul li a {
    font-size: 1.6rem;
    transition: var(--transition);
  }
  footer nav .block ul li:hover a {
    color: var(--primary-blue);
  }
  footer .bottom-block {
    width: 90%;
    margin: 0 auto;
    margin-top: 2rem;
    padding-top: 2rem;
    box-sizing: border-box;
    border-top: 0.2rem solid #0000004d;
  }
  footer .bottom-block p {
    color: #000000b3;
    font-size: 1.3rem;
  }
}
.unscroll {
  height: 100vh;
  overflow-y: hidden;
}
.skiptranslate {
  display: none !important;
}
/*
 <meta
          name="twitter:image"
          content={process.env.PUBLIC_URL + "favicon.ico"}
        />

   <meta
          name="twitter:card"
          content={process.env.PUBLIC_URL + "favicon.ico"}
        />  

    <meta
          property="og:image"
          content={process.env.PUBLIC_URL + "favicon.ico"}
        />
    <meta property="og:url" content="codesolutions.agenvy" />

*/
