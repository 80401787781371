header.white {
  position: static;
  background-color: #fff;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
}
header.white .languageBlock a.logo {
  font-size: 2.5rem;
  text-transform: uppercase;
  box-sizing: border-box;
}
header.white .languageBlock a.logo .blue {
  color: var(--primary-blue);
  font-family: "Bold";
}
header.white .languageBlock a.logo .white_underLine {
  position: relative;
  font-family: "Bold";
  color: #333;
  padding-bottom: 0.1rem;
}
header.white .languageBlock a.logo .white_underLine::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 0.2rem;
  width: 98%;
  border-radius: 1rem;
  background-color: #333;
}
header.white .languageBlock button {
  margin-left: 2rem;
  background-color: transparent;
  font-size: 1.4rem;
  font-family: "SemiBold";
  color: #bbb;
}
header.white .languageBlock button.ActiveLanguageButton {
  color: #000;
}
header.white nav button,
header.white nav a {
  color: #333;
}
header.white nav .routePCBlock {
  position: fixed;
  right: 0;
  border-radius: 0.5rem;
  top: 6rem;
  box-sizing: border-box;
  max-height: 0;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  z-index: 1;
}
header.white nav .routePCBlock ul {
  display: flex;
  margin: 2rem;
  flex-direction: column;
  align-items: flex-start;
}
header.white nav .routePCBlock ul li {
  margin-bottom: 2rem;
}
header.white nav .routePCBlock ul li a {
  font: 1.6rem "SemiBold";
  color: #333;
}
header.white nav .routePCBlock ul li:nth-last-child(1) {
  margin-bottom: 0;
}
header.white nav .s {
  max-height: 30rem;
}
.landing .firstScreen {
  width: 90%;
  margin: 0 auto;
  margin-top: 10rem;
  min-height: 50rem;
  background-image: url(../../../img/page-video.gif);
  background-size: cover;
  background-position: center center;
}
.landing .firstScreen .container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.landing .firstScreen .container .left,
.landing .firstScreen .container .right {
  width: 48%;
}
.landing .firstScreen .container .left p {
  font-size: 1.6rem;
  font-family: "SemiBold";
}
.landing .firstScreen .container .left p .blue {
  color: var(--primary-blue);
}
.landing .firstScreen .container .left h1 {
  font: 6rem "Bold";
  margin: 2rem 0;
}
.landing .firstScreen .container .right {
  margin-top: 8rem;
}
.landing .firstScreen .container .right p {
  color: #666;
  font-size: 1.6rem;
  width: 60%;
  margin: 0 auto;
}
.landing .oftenQuestions {
  width: 90%;
  margin: 0 auto;
  padding: 4rem 0;
}
.landing .oftenQuestions h2 {
  font: 3.2rem "SemiBold";
}
.landing .oftenQuestions .container {
  width: 60%;
}
.landing .oftenQuestions .container .block {
  margin-top: 2rem;
}
.landing .oftenQuestions .container button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  padding: 1.6rem;
  border-radius: 0.5rem;
  font-family: "Medium";
  font-size: 1.8rem;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #f3f3f3;
}
.landing .oftenQuestions .container button img {
  width: 1.8rem;
  transition: var(--transition);
  object-fit: contain;
}
.landing .oftenQuestions .container .inBlock {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
}
.landing .oftenQuestions .container .inBlock p {
  font: 1.6rem "Regular";
}
.landing .oftenQuestions .container .activeInBlock {
  padding: 1.6rem;
  max-height: 30rem;
}
.landing .oftenQuestions .container .activeButtonQuest img {
  transform: rotateZ(90deg);
}
.landing .tasks {
  width: 100%;
  padding: 4rem 0;
  background-color: #0a0a05;
  position: relative;
}
.landing .tasks .float {
  position: absolute;
  background-color: #1e2021;
  height: 20rem;
  width: 20rem;
}
.landing .tasks .floatBottomTrinagle {
  clip-path: polygon(100% 100%, 100% 20%, 20% 100%);
  z-index: 1;
  bottom: 0;
  right: 0;
}
.landing .tasks .floatTopTrinagle {
  clip-path: polygon(0 0, 80% 0, 0 80%);
  top: 0;
  left: 0;
}
.landing .tasks .container {
  width: 90%;
  margin: 7rem auto;
}
.landing .tasks .container h2 {
  font: 3.2rem "SemiBold";
  color: #fff;
}
.landing .tasks .container .tiles-container {
  display: flex;
  flex-wrap: wrap;
}
.landing .tasks .container .tiles-container .tile {
  width: 30%;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  margin-right: 3.33%;
  margin-top: 3.33%;
  animation: runFromTopWe 0.3s ease-in-out forwards;
  animation-play-state: paused;
}
@keyframes runFromTopWe {
  0% {
    transform: translateY(10rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
}
.landing .tasks .container .tiles-container .tile .row {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.landing .tasks .container .tiles-container .tile .row h4 {
  margin-right: 1rem;
  color: #656565;
  font: 1.4rem "Bold";
}
.landing .tasks .container .tiles-container .tile .row h3 {
  color: #fff;
  font: 2rem "Medium";
}
.landing .tasks .container .tiles-container .tile p {
  font: 1.6rem "Regular";
  color: #919191;
}
.landing .steps {
  width: 90%;
  padding: 4rem 0;
  margin: 0 auto;
}
.landing .steps h2 {
  font: 3.2rem "SemiBold";
}
.landing .steps .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}
.landing .steps .container .block {
  display: flex;
  width: 48%;
  align-items: flex-start;
  margin-top: 4%;
  animation: goFromLeft 0.3s ease-in-out forwards;
  animation-play-state: paused;
}
.landing .steps .container .block .row-a-c {
  display: flex;
  align-items: center;
}
.landing .steps .container .block .row-a-c .line {
  width: 5rem;
  height: 0.2rem;
  border-radius: 0.5rem;
  margin: 0 2rem;
  background-color: #ececec;
}
.landing .steps .container .block .circle {
  padding: 0.2rem;
  box-sizing: border-box;
  width: 3rem;
  border-radius: 50%;
  height: 3rem;
  border: 0.1rem solid var(--primary-blue);
}
.landing .steps .container .block .circle .inCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font: 1.6rem "Bold";
  height: 100%;
  border-radius: 50%;
  color: #fff;
  background-color: var(--primary-blue);
}
.landing .steps .container .block .inblock h3 {
  font: 2rem "Medium";
  color: #000;
  margin-bottom: 1rem;
}
.landing .steps .container .block .inblock p {
  font-size: 1.6rem;
  max-width: 70%;
  color: #555;
}
@keyframes goFromLeft {
  0% {
    transform: translateX(-10rem);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@media screen and (max-width: 768px) {
  header.white {
    z-index: 4;
  }
  header.white nav ul li.mobile button {
    background-color: #555;
  }
  .burger {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 0;
    overflow: hidden;
    transition: all 0.15s ease-in-out;
    height: 91.31%;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    background-color: #fff;
    z-index: 3;
  }
  .burger button,
  .burger .defaultLink {
    width: 80%;
    text-align: left;
    background-color: transparent;
    box-sizing: border-box;
    color: #333;
    margin-top: 2rem;
    font: 2rem "SemiBold";
  }
  .burger .block {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    width: 80%;
    max-height: 0;
    overflow: hidden;
    transition: all 0.15s ease-in-out;
  }
  .burger .block a {
    font: 1.8rem "Medium";
    color: #555;
    margin: 0.5rem 0;
  }
  .burger .contacts a {
    text-decoration: underline;
  }
  .burger .activeBurgerBlock {
    max-height: 30rem;
  }
  .activeBurger {
    width: 60%;
  }
  .landing .firstScreen {
    width: 90%;
    margin: 0 auto;
    margin-top: 2rem;
    min-height: 50rem;
    background-image: url(../../../img/page-video.gif);
    background-size: cover;
    background-position: center bottom;
  }
  .landing .firstScreen .container {
    display: flex;
    flex-direction: column;
    justify-content: unset;
    width: 100%;
  }
  .landing .firstScreen .container .left,
  .landing .firstScreen .container .right {
    width: 100%;
  }
  .landing .firstScreen .container .left p {
    font-size: 1.6rem;
    font-family: "SemiBold";
  }
  .landing .firstScreen .container .left p .blue {
    color: var(--primary-blue);
  }
  .landing .firstScreen .container .left h1 {
    font: 4rem "Bold";
    margin: 2rem 0;
    margin-bottom: 0;
  }
  .landing .firstScreen .container .right {
    margin: 2rem 0;
  }
  .landing .firstScreen .container .right p {
    color: #666;
    font-size: 1.6rem;
    width: 100%;
    margin: 0 auto;
  }
  .landing .oftenQuestions {
    width: 90%;
    margin: 0 auto;
    padding: 4rem 0;
  }
  .landing .oftenQuestions h2 {
    font: 2.6rem "SemiBold";
  }
  .landing .oftenQuestions .container {
    width: 100%;
  }
  .landing .oftenQuestions .container .block {
    margin-top: 2rem;
  }
  .landing .oftenQuestions .container button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
    padding: 1.6rem;
    border-radius: 0.5rem;
    font-family: "Medium";
    font-size: 1.6rem;
    box-sizing: border-box;
    cursor: pointer;
    background-color: #f3f3f3;
  }
  .landing .oftenQuestions .container button img {
    width: 1.8rem;
    transition: var(--transition);
    object-fit: contain;
  }
  .landing .oftenQuestions .container .inBlock {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;
  }
  .landing .oftenQuestions .container .inBlock p {
    font: 1.6rem "Regular";
  }
  .landing .oftenQuestions .container .activeInBlock {
    padding: 1.6rem;
    max-height: 30rem;
  }
  .landing .oftenQuestions .container .activeButtonQuest img {
    transform: rotateZ(90deg);
  }
  .landing .tasks {
    width: 100%;
    padding: 4rem 0;
    background-color: #0a0a05;
    position: relative;
  }
  .landing .tasks .float {
    position: absolute;
    background-color: #1e2021;
    height: 15rem;
    width: 15rem;
  }
  .landing .tasks .floatBottomTrinagle {
    clip-path: polygon(100% 100%, 100% 20%, 20% 100%);
    z-index: 1;
    bottom: 0;
    right: 0;
  }
  .landing .tasks .floatTopTrinagle {
    clip-path: polygon(0 0, 80% 0, 0 80%);
    top: 0;
    left: 0;
  }
  .landing .tasks .container {
    width: 90%;
    margin: 7rem auto;
    margin-bottom: 0;
  }
  .landing .tasks .container h2 {
    font: 2.6rem "SemiBold";
    color: #fff;
  }
  .landing .tasks .container .tiles-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .landing .tasks .container .tiles-container .tile {
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    margin-right: 0;
    margin-top: 3.33%;
    animation: runFromTopWe 0.3s ease-in-out forwards;
    animation-play-state: paused;
  }
  @keyframes runFromTopWe {
    0% {
      transform: translateY(10rem);
      opacity: 0;
    }
    100% {
      transform: translateY(0rem);
      opacity: 1;
    }
  }
  .landing .tasks .container .tiles-container .tile .row {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  .landing .tasks .container .tiles-container .tile .row h4 {
    margin-right: 1rem;
    color: #656565;
    font: 1.4rem "Bold";
  }
  .landing .tasks .container .tiles-container .tile .row h3 {
    color: #fff;
    font: 2rem "Medium";
  }
  .landing .tasks .container .tiles-container .tile p {
    font: 1.6rem "Regular";
    color: #919191;
  }
  .landing .steps {
    width: 90%;
    padding: 4rem 0;
    margin: 0 auto;
  }
  .landing .steps h2 {
    font: 2.6rem "SemiBold";
  }
  .landing .steps .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
  }
  .landing .steps .container .block {
    display: flex;
    width: 100%;
    align-items: flex-start;
    margin-top: 4rem;
  }
  .landing .steps .container .block .row-a-c {
    display: flex;
    width: 50%;
    align-items: center;
  }
  .landing .steps .container .block .row-a-c .line {
    width: 5rem;
    height: 0.2rem;
    border-radius: 0.5rem;
    margin: 0 2rem;
    background-color: #ececec;
  }
  .landing .steps .container .block .circle {
    padding: 0.2rem;
    box-sizing: border-box;
    width: 3rem;
    border-radius: 50%;
    height: 3rem;
    border: 0.1rem solid var(--primary-blue);
  }
  .landing .steps .container .block .circle .inCircle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font: 1.6rem "Bold";
    height: 100%;
    border-radius: 50%;
    color: #fff;
    background-color: var(--primary-blue);
  }
  .landing .steps .container .block .inblock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .landing .steps .container .block .inblock h3 {
    font: 2rem "Medium";
    color: #000;
    margin-bottom: 1rem;
  }
  .landing .steps .container .block .inblock p {
    font-size: 1.6rem;
    width: 100%;
    max-width: unset;
    color: #555;
  }
}
