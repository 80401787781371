header.white nav .routePCBlock {
  z-index: 96949684512 !important;
  background-color: #fff;
  opacity: 1;
}
.Portfolio .projects {
  width: 90%;
  margin: 0 auto;
  padding: 4rem 0;
  box-sizing: border-box;
}
.Portfolio .projects h1 {
  font: 3.2rem "SemiBold";
  margin: 2rem 0;
}
.Portfolio .projects p {
  max-width: 40%;
  font-size: 1.6rem;
  color: #666;
  line-height: 140%;
  margin-bottom: 3rem;
}
.Portfolio .projects .row-buttons {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 2rem;
}
.Portfolio .projects .row-buttons button {
  position: relative;
  padding: 2rem;
  box-sizing: border-box;
  min-width: 25%;
  transition: all 0.2s ease-in-out;
  transform-origin: right;
  border-top: 0.1rem solid #ddd;
  border-bottom: 0.1rem solid #ddd;
  z-index: 2;
  font: 1.8rem "SemiBold";
  background-color: transparent;
}
.Portfolio .projects .row-buttons button:nth-child(2) {
  border-top-left-radius: 10rem;
  border-left: 0.1rem solid #ddd;
  border-bottom-left-radius: 10rem;
}
.Portfolio .projects .row-buttons button:nth-last-child(1) {
  border-top-right-radius: 10rem;
  border-bottom-right-radius: 10rem;
  border-right: 0.1rem solid #ddd;
}
.Portfolio .projects .row-buttons .activeProjectButton {
  color: #fff;
}
.Portfolio .projects .row-buttons .color-change {
  position: absolute;
  font: 1.8rem "SemiBold";
  color: transparent;
  padding: 2rem;
  box-sizing: border-box;
  background-color: var(--primary-blue);
  left: 0;
  transition: all 0.3s ease-in-out;
  bottom: 0;
  min-width: 25%;
  z-index: 1;
  border-top: 0.1rem solid var(--primary-blue);
  border-bottom: 0.1rem solid var(--primary-blue);
}
.Portfolio .projects .row-buttons .color-change.left {
  border-top-left-radius: 10rem;
  border-left: 0.1rem solid var(--primary-blue);
  border-bottom-left-radius: 10rem;
}
.Portfolio .projects .row-buttons .color-change.right {
  border-top-right-radius: 10rem;
  border-right: 0.1rem solid var(--primary-blue);
  border-bottom-right-radius: 10rem;
}
.Portfolio .projects .container {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.Portfolio .projects .container .tiles-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.Portfolio .projects .container .tile {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #1c1c1c;
  margin-top: 2.5%;
  margin-right: 2.5%;
  width: 30%;
  min-height: 30rem;
  background-size: cover;
  border-radius: 0.5rem;
  animation: runFromLeftPortfolio 0.3s ease-in-out forwards;
  animation-play-state: paused;
  transition: all 0.3s easy-in-out;
  background-position: center center;
  overflow: hidden;
  position: relative;
}
.Portfolio .projects .container .tile::before {
  content: "";
  position: absolute;
  top: 0;
  transition: var(--transition);
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
@keyframes runFromLeftPortfolio {
  0% {
    opacity: 0;
    transform: translateY(10rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0rem);
  }
}
.Portfolio .projects .container .tile .bg {
  width: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 2rem;
  box-sizing: border-box;
  backdrop-filter: blur(5px);
  background-color: var(--default_dark05);
}
.Portfolio .projects .container .tile .bg .block p {
  color: #999;
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
}
.Portfolio .projects .container .tile .bg .block h3 {
  font-size: 2.1rem;
  color: #fff;
  text-transform: capitalize;
  font-family: "SemiBold";
}
.Portfolio .projects .container .tile .bg a {
  padding: 1rem 2rem;
  text-wrap: nowrap;
  box-sizing: border-box;
  transition: var(--transition);
  background-color: #fff;
  font-size: 1.8rem;
  border-radius: 10rem;
}
.Portfolio .projects .container .tile:hover:before {
  backdrop-filter: blur(5px);
}
.Portfolio .projects .container .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}
.Portfolio .projects .container .pagination a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 5rem;
  margin-right: 2rem;
  height: 5rem;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.1);
  font: 1.8rem "Medium";
}
.Portfolio .projects .container .pagination .active {
  background-color: var(--primary-blue);
  color: #fff;
}
.Portfolio .projects .activeProjects {
  max-height: unset;
}
@media screen and (max-width: 768px) {
  .Portfolio .projects {
    width: 90%;
    margin: 0 auto;
    padding: 4rem 0;
    padding-top: 0;
  }
  .Portfolio .projects h1 {
    font: 3.2rem "SemiBold";
    margin: 2rem 0;
  }
  .Portfolio .projects p {
    max-width: 100%;
    font-size: 1.6rem;
    color: #666;
    line-height: 140%;
    margin-bottom: 3rem;
  }
  .Portfolio .projects .row-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-bottom: 2rem;
  }
  .Portfolio .projects .row-buttons button {
    position: relative;
    text-align: left;
    padding: 2rem;
    box-sizing: border-box;
    min-width: 100%;
    min-height: 7rem;
    transition: all 0.2s ease-in-out;
    transform-origin: right;
    border: 0;
    border-right: 0.1rem solid #ddd;
    border-left: 0.1rem solid #ddd;
    z-index: 2;
    font: 1.8rem "SemiBold";
    background-color: transparent;
  }
  .Portfolio .projects .row-buttons button:nth-child(2) {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-top: 0.1rem solid #ddd;
    border-bottom-left-radius: 0;
  }
  .Portfolio .projects .row-buttons button:nth-last-child(1) {
    border-top-right-radius: unset;
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
    border-bottom: 0.1rem solid #ddd;
  }
  .Portfolio .projects .row-buttons .activeProjectButton {
    color: #fff;
  }
  .Portfolio .projects .row-buttons .color-change {
    position: absolute;
    font: 1.8rem "SemiBold";
    color: transparent;
    padding: 2rem;
    max-height: 7rem;
    box-sizing: border-box;
    background-color: var(--primary-blue);
    left: 0;
    transition: all 0.3s ease-in-out;
    top: 0;
    min-width: 100%;
    z-index: 1;
    border-top: 0.1rem solid var(--primary-blue);
    border-bottom: 0.1rem solid var(--primary-blue);
  }
  .Portfolio .projects .row-buttons .color-change.left {
    border-top-left-radius: 10rem;
    border-left: 0.1rem solid var(--primary-blue);
    border-bottom-left-radius: 10rem;
  }
  .Portfolio .projects .row-buttons .color-change.right {
    border-top-right-radius: 10rem;
    border-right: 0.1rem solid var(--primary-blue);
    border-bottom-right-radius: 10rem;
  }
  .Portfolio .projects .row-buttons .color-change.top {
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
    border-right: 0.1rem solid var(--primary-blue);
  }
  .Portfolio .projects .row-buttons .color-change.bottom {
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
    border-right: 0.1rem solid var(--primary-blue);
  }
  .Portfolio .projects .container {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }
  .Portfolio .projects .container .tiles-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
  }
  .Portfolio .projects .container .tile {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #1c1c1c;
    margin-top: 2.5%;
    margin-right: 2.5%;
    width: 100%;
    min-height: 30rem;
    background-size: cover;
    border-radius: 0.5rem;
    animation: runFromLeftPortfolio 0.3s ease-in-out forwards;
    animation-play-state: paused;
    transition: all 0.3s easy-in-out;
    background-position: center center;
    overflow: hidden;
    position: relative;
  }
  .Portfolio .projects .container .tile::before {
    content: "";
    position: absolute;
    top: 0;
    transition: var(--transition);
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  @keyframes runFromLeftPortfolio {
    0% {
      opacity: 0;
      transform: translateY(10rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0rem);
    }
  }
  .Portfolio .projects .container .tile .bg {
    width: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 2rem;
    box-sizing: border-box;
    backdrop-filter: blur(5px);
    background-color: var(--default_dark05);
  }
  .Portfolio .projects .container .tile .bg .block p {
    color: #999;
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
  }
  .Portfolio .projects .container .tile .bg .block h3 {
    font-size: 2.1rem;
    color: #fff;
    text-transform: capitalize;
    font-family: "SemiBold";
  }
  .Portfolio .projects .container .tile .bg a {
    padding: 1rem 2rem;
    text-wrap: nowrap;
    box-sizing: border-box;
    transition: var(--transition);
    background-color: #fff;
    font-size: 1.8rem;
    border-radius: 10rem;
  }
  .Portfolio .projects .container .tile:hover:before {
    backdrop-filter: blur(5px);
  }
  .Portfolio .projects .container .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
  }
  .Portfolio .projects .container .pagination a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 5rem;
    margin-right: 2rem;
    height: 5rem;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.1);
    font: 1.8rem "Medium";
  }
  .Portfolio .projects .container .pagination .active {
    background-color: var(--primary-blue);
    color: #fff;
  }
  .Portfolio .projects .activeProjects {
    max-height: unset;
  }
}
